import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App.tsx';
import AuthProvider from './providers/AuthProvider.tsx';
import { ErrorBoundary } from './components/ErrorBoundary.tsx';
import { ToastContainer } from 'react-toastify';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import NiceModal from '@ebay/nice-modal-react';

import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <NiceModal.Provider>
          <AuthProvider>
            <ErrorBoundary>
              <App />
              <ToastContainer />
            </ErrorBoundary>
          </AuthProvider>
        </NiceModal.Provider>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
);
